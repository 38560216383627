import React from "react";
import { Card, Container, Button } from "react-bootstrap";
import {
  companionCard, cardBody, companionsBtn, cardLink,
  cardTitle, companions, companion,
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";
import Slider from "react-slick";
import Link from "components/link";

const CollectionList = ({ data }) => {

  const { btnText, btnUrl, collectionProduct } = data;

  var settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll:1,
          initialSlide: 1,
          rows: 1,
          slidesPerRow: 1,
        }
      },
    ]
  };

  return (
    <div className={companions}>
      <Container>
        <Slider {...settings} >
          { 
            collectionProduct.map((c) => (
              <div className={companion} key={c._key}>
                <Link href={c.prodUrl} className={cardLink}>
                  <Card className={companionCard} >
                    <Card.Img variant="top" src={getImagePath(c.prodImage)}  />
                    <Card.Body className={cardBody}>
                      <Card.Title className={cardTitle}>{c.prodName}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            ))
          }
        </Slider>
        <Button href={btnUrl} className={companionsBtn}>{btnText}</Button>
      </Container>
    </div>
  );
};

export default CollectionList;
